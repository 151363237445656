.back-button {
    background-color: var(--secondary-color);
    border: 1.2px solid #000000;
    color: #333;
    padding: 3px 25px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;    
    display: inline-flex;
    align-items: center;
    text-decoration: none;
}

.back-button svg {
    margin-right: 8px;
    width: 16px;
    height: 16px;
}

.back-b {
    margin-top: 2px;
}

.img {
    width: 100%;
    border-radius: 3px;
}

.back-button:hover {
    background-color: #e0e0e0;
}

.box {
    display: flex;
    flex-direction: row;
    margin-top: 1.3em;
}

.box h3 {
    flex-basis: 30%;
    text-align: center;
}

.box p {
    flex-basis: 70%;
}