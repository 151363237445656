@font-face {
  font-family: 'ABCDiatypeRoundedPlusVariable';
  src: url('./assets/fonts/ABCDiatypeRoundedPlusVariable-Trial.ttf');
}

@font-face {
  font-family: 'ABCDiatypeRoundedSemiMono';
  src: url('./assets/fonts/ABCDiatypeRoundedSemi-Mono-Regular-Trial.otf') format('opentype');
}

:root {
  --primary-color: #222222;
  --secondary-color: #E4DFDD;
}

.app {
  background-color: var(--secondary-color);
  font-family: 'ABCDiatypeRoundedPlusVariable', sans-serif;
  color: var(--primary-color);
  font-size: 18px;
  line-height: 1.5;
  text-align: justify;
}

/* .app p, .app ul, .app ol {
  font-family: 'ABCDiatypeRoundedSemiMono', 'ABCDiatypeRoundedPlusVariable', sans-serif;
} */


.links-f {
  color: inherit; 
  text-decoration: none;
}

.app-container {
  max-width: 600px;
  padding: 4rem .5rem;
  margin: 0 auto;
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-container {
  padding-top: 2rem;
}

.title-main {
  padding-bottom: 4rem;
}

.app-center {
  display: flex;
  justify-content: center;
}

.spacing-title {
  margin-top: 0.5rem;
}

.spacing-large {
  margin-top: 4rem;
}

.spacing-medium {
  margin-top: 2rem;
}

.spacing-small {
  margin-top: 1rem;
}

.cursor {
  background-color: #000;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 9999;
  mix-blend-mode: difference;
  transition: background-color 0.3s ease, mix-blend-mode 0.3s ease;
}

.green {
  background-color: #136F63;
  mix-blend-mode: normal;
}

body {
  cursor: none;
}

.links {
  text-decoration: none;
  color: var(--primary-color);
  position: relative;
  transition: color 0.3s ease;
}

.links::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background-color: var(--primary-color);
  transition: background-color 0.3s ease, width 0.3s ease;
}

.links:hover {
  color: #136F63;
}

.links:hover::after {
  background-color: #136F63;
  width: 0;
}

/* Responsive design */
/* Responsive design */
@media (max-width: 1024px) {
  .app-container {
    padding: 2rem 1rem;
  }
}

@media (max-width: 768px) {
  .app-container {
    padding: 3rem 1.5rem;
  }

  .spacing-large {
    margin-top: 2rem;
  }

  .spacing-medium {
    margin-top: 1rem;
  }

  body, h1, h2, h3, h4, h5, h6, p, ol, ul, li, a {
    font-size: 16px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 20px;
  }

  .big {
    font-size: 18px;
  }

  .title-container {
    padding-top: 4rem;
  }
  .title-main {
    padding-bottom: 4rem;
  }
}



@media (max-width: 480px) {
  .app-container {
    padding: 2rem 1rem;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 18px;
  }

  .big {
    font-size: 16px;
  }
}

.header-wrapper {
  position: relative;
  cursor: none;
}

.header-wrapper::before {
  content: attr(data-text);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: white;
  -webkit-clip-path: circle(20px at var(--mouse-x) var(--mouse-y));
  clip-path: circle(20px at var(--mouse-x) var(--mouse-y));
  pointer-events: none;
}

.project-image video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .cursor {
    display: none;
  }

  body {
    cursor: auto;
  }

  .links::after {
    height: 1px;
    bottom: -3px;
  }

  .links:hover::after {
    width: 100%;
  }
}

.links::after {
  height: 1px;
}

.links:hover::after {
  width: 100%;
  background-color: #136F63;
}