:root {
  /* --font--base :  */
  
  --primary-color: #222222;
  --secondary-color: #E4DFDD;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul, li, a {
  font-size: 20px;
  
}