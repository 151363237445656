.project-container {
  position: relative;
}

.project-image {
  position: fixed;
  right: 0px;
  top: 10px;
  width: 45vw;
  max-width: 650px;
  aspect-ratio: 16 / 9;
  display: flex;
  align-items: center;
  z-index: 1;
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  opacity: 0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  transform: scale(0.95);
}

.project-image.visible {
  opacity: 1;
  transform: scale(1);
}

.project-image video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.project-video-container {
  position: relative;
}

.project-subtitle {
  font-family: 'ABCDiatypeRoundedPlusVariable', sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: var(--primary-color);
  margin-top: 5px;
  opacity: 0.7;
}

@media (max-width: 1200px) {
  .project-image {
    width: 40vw;
    max-width: 500px;
  }
}

@media (max-width: 992px) {
  .project-image {
    width: 35vw;
    max-width: 400px;
  }
}

@media (max-width: 768px) {
  .project-image {
    width: 30vw;
    max-width: 300px;
    right: 20px;
  }
}

@media (max-width: 576px) {
  .project-image {
    width: 25vw;
    max-width: 200px;
    right: 15px;
  }
}

.project-item {
  position: relative;
}

.mobile-video {
  display: none;
  width: 100%;
  height: auto;
  margin-top: 10px;
  aspect-ratio: 16 / 9;
  object-fit: contain;
  background-color: #000;
}

@media (max-width: 768px) {
  .project-image {
    display: none;
  }

  .mobile-video {
    display: block;
  }

  .project-container {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .project-item {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media (max-width: 768px) {
  .project-container ol {
    list-style-type: none;
    padding-left: 0;
  }

  .project-container li {
    margin-bottom: 15px;
  }
}

@media (max-width: 768px) {
  .past-project-link {
    display: inline-block;
    margin-bottom: 10px;
  }

  .past-project-link::after {
    bottom: -4px;
  }
}