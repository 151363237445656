.music-container {
    position: relative;
  }
  
  .lol {
    color: var(--secondary-color);
    position: absolute;
  }
  
  .animated-lines-container {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  @media (max-width: 768px) {
    .lol {
      position: static;
      margin-top: 0.5em;
    }
  }