.animated-lines-container {
  display: flex;
  height: 30px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.wave-line {
  width: 10%;
  height: 30px;
  overflow: hidden;
}

.animated-line {
  animation: wave 2s ease-in-out infinite;
}

@keyframes wave {
  0%, 100% {
    d: path("M0,15 Q25,5 50,15 T100,15");
  }
  50% {
    d: path("M0,15 Q25,25 50,15 T100,15");
  }
}

.line-0 { animation-delay: -1.8s; }
.line-1 { animation-delay: -1.6s; }
.line-2 { animation-delay: -1.4s; }
.line-3 { animation-delay: -1.2s; }
.line-4 { animation-delay: -1.0s; }
.line-5 { animation-delay: -0.8s; }
.line-6 { animation-delay: -0.6s; }
.line-7 { animation-delay: -0.4s; }
.line-8 { animation-delay: -0.2s; }
.line-9 { animation-delay: 0s; }

@media (max-width: 768px) {
  .animated-lines-container {
    display: flex;
    height: 15px;
  }

  .wave-line {
    height: 15px;
  }

  .animated-line {
    stroke-width: 1;
  }
}